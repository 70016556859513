// Taken from https://source.chromium.org/chromium/chromium/src/+/main:third_party/blink/web_tests/fast/forms/resources/ValidityState-typeMismatch-email.js?q=ValidityState-typeMismatch-email.js&ss=chromium
const validateEmail = (email) =>
  String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

export default validateEmail;

const PERSONAL_DOMAIN_REGEX =
  /@(gmail\..+|outlook\..+|yahoo\..+|hotmail\..+|icloud\..+|qq\..+|protonmail\..+|proton\..+)$/i;

export function isPersonalEmailDomain(email) {
  return PERSONAL_DOMAIN_REGEX.test(email);
}
