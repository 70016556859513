import React from 'react';
import Image from 'next/image';
import { Box, Typography, Grid } from '@mui/material';
import useTranslation from 'next-translate/useTranslation';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import gravityImg from '../../public/companies/gravity.png';
import burstiqImg from '../../public/companies/burstiq.png';
import appruvrImg from '../../public/companies/appruvr.png';
import sevenmileImg from '../../public/companies/sevenmile.png';
import polygonImg from '../../public/companies/polygon.png';
import classterImg from '../../public/companies/classter.png';

export default function FeaturesList() {
  const { t } = useTranslation(['auth', 'common']);

  const features = [
    t('auth:feature-list-1'),
    t('auth:feature-list-2'),
    t('auth:feature-list-3'),
    t('auth:feature-list-4'),
  ];

  return (
    <Box
      sx={{
        marginTop: { xs: '30px', md: 0 },
      }}>
      <Typography
        variant="h2"
        sx={{ fontSize: '48px', color: '#FFFFFF', fontWeight: 500, marginBottom: '24px' }}>
        {t('auth:feature-list-title')}
      </Typography>
      <br />

      {features.map((feature, index) => (
        <Grid container key={index} gap="8px" alignItems="center" sx={{ marginBottom: '20px' }}>
          <CheckCircleOutlineIcon color="#B2CCFF" fontSize="medium" sx={{ color: '#B2CCFF' }} />
          <Typography
            variant="body1"
            sx={{
              display: 'flex',
              color: '#B2CCFF',
              fontWeight: 500,
            }}>
            {feature}
          </Typography>
        </Grid>
      ))}

      <br />

      <Typography
        variant="body1"
        sx={{
          display: 'flex',
          color: '#B2CCFF',
          fontWeight: 500,
          marginTop: '24px',
        }}>
        {t('auth:partners-list-title')}
      </Typography>

      <Box
        sx={{
          display: 'flex',
          gap: '20px',
          '& > *': {
            width: '136px',
            height: '64px',
            backgroundBlendMode: 'screen',
          },
        }}>
        <Image src={gravityImg} alt="Gravity Logo" />
        <Image src={burstiqImg} alt="BurstIQ Logo" />
        <Image src={appruvrImg} alt="Appruvr Logo" />
      </Box>

      <Box
        sx={{
          display: 'flex',
          gap: '20px',
          '& > *': {
            width: '136px',
            height: '64px',
            backgroundBlendMode: 'screen',
          },
        }}>
        <Image src={sevenmileImg} alt="Sevenmile Logo" />
        <Image src={polygonImg} alt="Polygon Logo" />
        <Image src={classterImg} alt="Classter Logo" />
      </Box>
    </Box>
  );
}
